// extracted by mini-css-extract-plugin
export var column = "LocationBrochureEdit__column__VXDYM";
export var container = "LocationBrochureEdit__container__YJujo";
export var flex = "LocationBrochureEdit__flex__m07wx";
export var flexColumn = "LocationBrochureEdit__flexColumn__bUXwC";
export var gap1 = "LocationBrochureEdit__gap1__wGzBz";
export var gap2 = "LocationBrochureEdit__gap2__uW0yq";
export var gap3 = "LocationBrochureEdit__gap3__TYFmP";
export var gap4 = "LocationBrochureEdit__gap4__xNXnf";
export var gap5 = "LocationBrochureEdit__gap5__uL0lf";
export var pageContainer = "LocationBrochureEdit__pageContainer__cyZU3";
export var pageTitle = "LocationBrochureEdit__pageTitle__zIK2Y";
export var row = "LocationBrochureEdit__row__gfUZo";